import React from "react"
import DepartmentData from "../../contents/departments.yaml"
import cliTruncate from 'cli-truncate'
const DepartmentsAll = ({ style }) => {
  return (
    <section
      className="services-area"
      style={{ paddingTop: 80, paddingBottom: 100, ...style }}
    >
      <div className="container">
        <div className="row m-0">
          {DepartmentData.contents.map((d, i) => (
            <div
              key={`department_${i}`}
              className="col-lg-4 col-md-6 col-sm-6 p-0"
            >
              <div className="single-services-box department-slide-item">
                <h3
                  style={{ minHeight: 60 }}
                  className={"d-flex align-items-center"}
                >
                  <i className={d.icon}></i> {d.name}
                  {/* <br />
                    Treatment */}
                </h3>
                <p>{cliTruncate(d.content_short, 100)}</p>
                <a href="#" className="read-more-btn" data-bs-toggle="modal" data-bs-target={`#modal-${d.slug}`}>
                  View More
                </a>
              </div>
            </div>
          ))}
        </div>

        {
          DepartmentData.contents.map((d, i) => (
            <div key={i} className="modal fade" id={`modal-${d.slug}`} tabIndex="-1" role="dialog" aria-labelledby={`modal-${d.slug}-title`} aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={`modal-${d.slug}-long-title`}>
                      {/* <i className={d.icon}></i> &nbsp; */}
                    Department of {d.name}
                    </h5>
                  </div>
                  <div className="modal-body"> {d.content_long} </div>
                  <div className="modal-footer">
                    <button type="button" className="modal-footer-button" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default DepartmentsAll
