import React from "react"
import DepartmentsAll from "../components/DepartmentsAll"
import EmergencyMedicalCareBanner from "../components/EmergencyMedicalCareBanner"
import FacilitiesGrid from "../components/FacilitiesGrid"
import Seo from "../components/Seo"
import Testimonials from "../components/Testimonials"
import withLayout from "../layout/withLayout"
const AboutPage = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title="About Us" description="A few word about Nemcare Hospital Tezpur and its Superspeciality" siteMetadata={siteData} />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>About Us</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>About</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <img src="/img/doctors/md_550_800.jpg" alt="image" />
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span className="sub-title">Directors Desk</span>
                <h2>Few Words From the Managing Director</h2>
                <p>
                  It is my joy and privilege to bring warm greetings from
                  NEMCARE Hospital Tezpur Pvt Ltd. All set to become a lead next
                  in the field of Medical Care. Nemcare Hospital Tezpur Pvt Ltd
                  has gone leaps and bounds to deliver it's mission of bringing
                  quality health care services for all in the entire Sonitpur
                  District.
                </p>
                <p>
                  The team of our expert medical professionals will constantly
                  endeavour to provide accessible, affordable and best health
                  care services in the entire Sonitpur District.
                </p>
                <p>
                  Life is our greatest gift, where every moment is as new as it
                  is special. Life is about living every second to the fullest
                  and about making every day an experience to enjoy and cherish.
                </p>
                <p>
                  We aim that our hospital leaves an indelible mark in the heart
                  of every patient and their family members.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FacilitiesGrid />

      <section className="testimonial-area pt-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Departments</span>
            <h2>Speciality at Nemcare Hospital Tezpur</h2>
          </div>
        </div>
      </section>

      <DepartmentsAll style={{ paddingTop: 10 }} />

      <EmergencyMedicalCareBanner siteData={siteData} />

      <Testimonials />
    </>
  )
})

export default AboutPage
