import React from "react"
import FacilityData from "../../contents/facilities.yaml"
import TruncatedParagraph from "./helpers"

const FacilitiesGrid = () => {
  return (
    <section
      className="facilities-content"
      style={{ paddingTop: 80, paddingBottom: 100 }}
    >
      <div className="content" style={{ paddingLeft: 10 }}>
        <div className="row">
          <div className={"col-12"}>
            <span className="sub-title">Why You Choose Us</span>
            <h2>Services and Facilities at a Glance</h2>
            <p>
              The services and facilities at the premises meets superspeciality standards
              so that we can offer utmost care by early dignosis and high throughput treatments
            </p>
          </div>
        </div>
        <div className="row">
          {FacilityData.contents.map((f, i) => (
            <div className="col-sm-12 col-md-4 facility-item d-flex justify-content-between align-items-center">
              <div style={{ marginRight: 20 }}>
                <i className={f.icon}></i>
              </div>
              <div>
                <span>{f.title}</span>
                <TruncatedParagraph letters={80} text={f.description_short} style={{ textAlign: 'left' }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FacilitiesGrid
