import React from "react"
const EmergencyMedicalCareBanner = ({ siteData }) => {
  return (
    <section
      className="cta-area ptb-100 jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div className="container">
        <div className="cta-content">
          <div className="icon">
            <i className="flaticon-business-and-finance"></i>
          </div>
          <h2>Emergency Medical Care 24/7</h2>
          <p>
            With access to 24 hour emergency assistance, It’s so important you
            can continue to help others.
          </p>
          <a href="#" className="call-us">
            <i className="flaticon-phone-call"></i> {siteData.emergency_contact}
          </a>
        </div>
      </div>
    </section>
  )
}

export default EmergencyMedicalCareBanner
