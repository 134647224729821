import React, { useEffect } from "react"
const Testimonials = () => {
  useEffect(() => {
    const { $ } = window
    // Feedback Carousel
    var $imagesSlider = $(".testimonial-slides .client-feedback>div"),
      $thumbnailsSlider = $(".client-thumbnails>div")
    // Images Options
    $imagesSlider.slick({
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "linear",
      fade: true,
      autoplay: false,
      draggable: true,
      asNavFor: ".client-thumbnails>div",
      prevArrow: ".client-feedback .prev-arrow",
      nextArrow: ".client-feedback .next-arrow",
    })
    // Thumbnails Options

    $thumbnailsSlider.slick({
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 1,
      cssEase: "linear",
      autoplay: false,
      centerMode: true,
      draggable: false,
      focusOnSelect: true,
      asNavFor: ".testimonial-slides .client-feedback>div",
      prevArrow: ".client-thumbnails .prev-arrow",
      nextArrow: ".client-thumbnails .next-arrow",
    })
  }, [])
  return (
    <section className="testimonial-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Testimonials</span>
          <h2>A Token of Happiness from our Satisfied Patients</h2>
          <p>
            We have delivered our best efforts to make effective treatment and dignostics
            at very low cost. Which is reflected as acknowledging testimony from our patients.
          </p>
        </div>
        <div className="testimonial-slides">
          <div className="client-feedback">
            <div>
              <div className="item">
                <div className="single-feedback">
                  <p>
                    “The service which provided by Nemcare Hospital Tezpur is
                    really excellent. Doctors are very friendly with the
                    patients, hope the hospital maintains the same in future
                    also. I wish them all the very best.”
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="single-feedback">
                  <p>
                    “Nemcare Hospital Tezpur treatment facilities are very good.
                    The rooms and toilets are maintained very well, very neat.
                    Doctors are really take interest in each patient. They talk
                    to you very well. We are very much impressed Nemcare's
                    treatment. Thanking you.”
                  </p>
                </div>
              </div>
            </div>
            <button className="prev-arrow slick-arrow">
              <i className="flaticon-back"></i>
            </button>
            <button className="next-arrow slick-arrow">
              <i className="flaticon-next-1"></i>
            </button>
          </div>

          {/* Client Thumbnails */}
          <div className="client-thumbnails">
            <div>
              <div className="item">
                {/* <div className="img-fill">
                  <img src="assets/img/client-image/5.jpg" alt="client" />
                </div> */}
                {/* <div className="title">
                  <h3>James Anderson</h3>
                  <span>CEO at Facebook</span>
                </div> */}
              </div>
              <div className="item">
                {/* <div className="img-fill">
                  <img src="assets/img/client-image/3.jpg" alt="client" />
                </div> */}
                {/* <div className="title">
                  <h3>Steven Smith</h3>
                  <span>CEO at EnvyTheme</span>
                </div> */}
              </div>
            </div>
          </div>
          {/* End Client Thumbnails */}
        </div>
      </div>
    </section>
  )
}

export default Testimonials
