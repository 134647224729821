import React, { useState } from 'react';
import cliTruncate from 'cli-truncate'
const TruncatedParagraph = ({ letters = 100, text, ...props }) => {
    const [showMore, toggle] = useState(true)
    if (showMore && text.length > letters) return <p {...props}>
        {cliTruncate(text, letters)} &nbsp;
        <a href="#" className={"inline-text-link"} onClick={(e) => {
            e.preventDefault();
            toggle(!showMore)
        }}>View More</a>
    </p>
    else
        return <p {...props}>
            {text}
            {
                (text.length > letters) &&
                <a href="#" className={"inline-text-link"} onClick={(e) => {
                    e.preventDefault();
                    toggle(!showMore)
                }}> View Less</a>

            }
        </p>;
}

export default TruncatedParagraph;